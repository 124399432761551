import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
    Asset,
    Brand,
    BrandList,
    Category,
    CategoryList,
    CurrencyEnum,
    ElasticProduct,
    ElasticProductKeyEnum,
    Product,
    ProductQuery,
    ProviderList,
    Tax,
} from '@webcoffee/interfaces';
import { EMPTY, Observable, catchError, filter, switchMap, tap } from 'rxjs';
import { ToasterService } from '../../shared/utils/services/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TableLazyLoadEvent } from 'primeng/table';
import { cleanNumber } from '../utils/maths';

export interface ProductState {
    products: ElasticProduct[] | null;
    categories: CategoryList[];
    brands: BrandList[];
    providers: ProviderList[];
    productsCount: number | null;
    selectedProduct: Product | null;
    focusedProduct: Product | null;
    loading: boolean;
    editMode: boolean;
    query: ProductQuery;
    lazyTable: TableLazyLoadEvent | null;
    taxes: Tax[];
    currencyRates: Record<CurrencyEnum, number>;
}

@Injectable()
export class ProductStore extends ComponentStore<ProductState> {
    constructor(
        private http: HttpClient,
        private toastr: ToasterService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        super({
            products: null,
            categories: [],
            brands: [],
            providers: [],
            productsCount: null,
            selectedProduct: null,
            focusedProduct: null,
            loading: false,
            editMode: false,
            query: { skip: 0, take: 10, sort: '-updated' },
            lazyTable: null,
            taxes: [],
            currencyRates: null,
        });
    }

    readonly getProducts = this.select((state) => state.products);
    readonly getCount = this.select((state) => state.productsCount);
    readonly getQuery = this.select((state) => state.query);
    readonly getCategories = this.select((state) => state.categories);
    readonly getBrands = this.select((state) => state.brands);
    readonly getProviders = this.select((state) => state.providers);
    readonly getEditMode = this.select((state) => state.editMode);
    readonly getSelectedProduct = this.select((state) => state.selectedProduct);
    readonly getFocusedProduct = this.select((state) => state.focusedProduct);
    readonly getNewProduct = this.select(this.getEditMode, this.getSelectedProduct, (editMode, selectedProduct) => editMode && !selectedProduct);
    readonly getProviderItem = this.select(this.getSelectedProduct, (product) => product?.provider_item);
    readonly getTaxes = this.select((state) => state.taxes);
    readonly getCurrencyRates = this.select((state) => state.currencyRates);

    readonly updateQuery = this.updater((state, payload: any) => {
        let query = { ...state.query, ...payload };

        query = Object.keys(query).reduce((parsedQuery, key) => {
            if (typeof query[key] === 'string') {
                if (query[key]?.trim() === '') return parsedQuery;
            }
            return { ...parsedQuery, [key]: query[key] };
        }, {});

        this.router.navigate([], { relativeTo: this.route, queryParams: query });

        return { ...state, query };
    });
    readonly setQuery = this.updater((state, payload: any) => {
        this.router.navigate([], { relativeTo: this.route, queryParams: payload });

        return { ...state, query: payload };
    });

    readonly addAsset = this.updater((state, payload: Asset) => {
        return { ...state, selectedProduct: { ...state.selectedProduct, assets: [...state.selectedProduct.assets, payload] } };
    });
    readonly removeAsset = this.updater((state, payload: string) => {
        return { ...state, selectedProduct: { ...state.selectedProduct, assets: [...state.selectedProduct.assets].filter((a) => a.id !== payload) } };
    });
    readonly mapProducts = this.updater((state) => {
        const products = [...(state.products || [])];

        products.forEach((product, index) => {
            const currency = product.price.currency;

            if (currency !== CurrencyEnum.RON) {
                const price = cleanNumber(product.price[ElasticProductKeyEnum.PRICE] / state.currencyRates[currency]);
                const discounted_price = product.price[ElasticProductKeyEnum.DISCOUNTED_PRICE]
                    ? cleanNumber(product.price[ElasticProductKeyEnum.DISCOUNTED_PRICE] / state.currencyRates[currency])
                    : null;

                products.splice(index, 1, { ...product, price: { ...product.price, price, discounted_price } });
            }
        });

        return { ...state, products };
    });

    readonly watchProductIdEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            tap((id: string) => {
                if (id) {
                    if (id === 'nou') {
                        this.patchState({ loading: false, editMode: true, selectedProduct: null });
                    } else {
                        if (this.get().selectedProduct?.id !== id) {
                            this.patchState({ loading: true, editMode: true });
                            this.getProductEffect(id);
                        }
                    }
                }
            }),
        ),
    );

    readonly getCurrencyRatesEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get('/api/currency').pipe(
                    tap({
                        next: (currency: { rates: Record<CurrencyEnum, number> }) => {
                            this.patchState({ currencyRates: currency.rates });
                            this.mapProducts();
                        },
                        error: (err) => this.toastr.error(err.error.message),
                    }),
                ),
            ),
        ),
    );

    readonly getTaxesEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get<Tax[]>('/api/products/taxes').pipe(
                    tap({
                        next: (taxes) => {
                            this.patchState({ taxes });
                        },
                        error: (err) => this.toastr.error(err.error.message),
                    }),
                ),
            ),
        ),
    );

    readonly getCategoriesEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) => {
                return this.http.get<CategoryList[]>('/api/categories/list').pipe(
                    tap((categories) => {
                        this.patchState({ categories });
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                );
            }),
        ),
    );

    readonly getBrandsEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) => {
                return this.http.get<BrandList[]>('/api/brands/list').pipe(
                    tap((brands) => {
                        this.patchState({ brands });
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                );
            }),
        ),
    );

    readonly getProvidersEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) => {
                return this.http.get<ProviderList[]>('/api/providers/list').pipe(
                    tap((providers) => {
                        const mappedProviders = [];

                        for (const provider of providers) {
                            const skuSet = new Set();

                            for (const providerItem of provider.provider_items) {
                                skuSet.add(`${providerItem.sku}`);
                            }
                            mappedProviders.push({ ...provider, skus: skuSet });
                        }

                        this.patchState({ providers: mappedProviders });
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                );
            }),
        ),
    );

    readonly getProductsEffect = this.effect((params$: Observable<any>) =>
        params$.pipe(
            switchMap((params) => {
                let decodedParams = new HttpParams();

                for (const [queryKey, queryVal] of Object.entries(params)) {
                    if (typeof queryVal === 'string') {
                        decodedParams = decodedParams.append(queryKey, queryVal);
                    } else {
                        decodedParams = decodedParams.append(queryKey, JSON.stringify(queryVal));
                    }
                }
                return this.http.get<{ count: number; items: ElasticProduct[] }>('/api/products/search', { params: decodedParams }).pipe(
                    tap((countAndProducts) => {
                        const products = [...countAndProducts?.items];
                        const currencyRates = this.get().currencyRates;

                        if (currencyRates) {
                            products.forEach((product, index) => {
                                const currency = product.price.currency;

                                if (currency !== CurrencyEnum.RON) {
                                    const price = cleanNumber(product.price[ElasticProductKeyEnum.PRICE] / currencyRates[currency]);
                                    const discounted_price = product.price[ElasticProductKeyEnum.DISCOUNTED_PRICE]
                                        ? cleanNumber(product.price[ElasticProductKeyEnum.DISCOUNTED_PRICE] / currencyRates[currency])
                                        : null;

                                    products.splice(index, 1, { ...product, price: { ...product.price, price, discounted_price } });
                                }
                            });
                        }

                        this.patchState({ productsCount: countAndProducts?.count, products });
                    }),
                );
            }),
        ),
    );

    readonly getProductEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.http.get<Product>(`api/products/${id || this.get().selectedProduct?.id}`).pipe(
                    tap((product) => {
                        this.patchState({ selectedProduct: product, loading: false });
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly deactivateProductEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.http.patch(`/api/products/${id}/deactivate`, {}).pipe(
                    tap({
                        next: () => {
                            this.toastr.success('Produsul a fost dezactivat cu success');
                            this.getProductsEffect(this.get().query);
                        },
                        error: (err) => this.toastr.error(err.error.message),
                    }),
                ),
            ),
        ),
    );

    readonly deleteProductEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.http.delete(`/api/products/${id}`).pipe(
                    tap({
                        next: () => {
                            this.toastr.success('Produsul a fost sters cu success');
                            setTimeout(() => this.getProductsEffect(this.get().query), 1000);
                        },
                        error: (err) => this.toastr.error(err.error.message),
                    }),
                ),
            ),
        ),
    );
}
