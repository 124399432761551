export function addPercentToPrice(price: number, percent: number) {
    return cleanNumber(price * (1 + percent / 100));
}

export function substractPercentFromPrice(price: number, percent: number) {
    return cleanNumber(price * (1 - percent / 100));
}

export function findBasePriceFromIncreasedPrice(price: number, percent: number) {
    return cleanNumber(price / (1 + percent / 100));
}

export function findPercentOfIncreasedPrice(base_price: number, price: number) {
    return cleanNumber(100 * (price / base_price - 1));
}

export function findBasePriceFromDecreasePrice(price: number, percent: number) {
    return cleanNumber(price / (1 - percent / 100));
}

export function findPercentOfDecreasedPrice(base_price: number, price: number) {
    if (price) {
        return cleanNumber(100 * (1 - price / base_price));
    }
    return null;
}

export function cleanNumber(n: number) {
    return Number(n.toFixed(2));
}
